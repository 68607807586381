import React from "react"
import Container from "../container/container"
import style from "./contact.mod.scss"
import Intro from "./intro"
import Wrap from "./wrap"
import { motion } from "framer-motion"

const ContactSection = ({ title, description, locations, navigation = false }) => {
  const headingVariants = {
    offscreen: {
      x: 30,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 2.5,
      },
    },
  }

  const formVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 2.5,
        delay: 0.3,
      },
    },
  }  

  return (
    <>
      <div className={style.contact}>
        <Container width={"large"} gutters={"true"}>
          <Container pt={0} width={"large"}>
            <motion.div
              variants={headingVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{}}
            >
              <Intro title={title} navigation={navigation} />
            </motion.div>
          </Container>
          <Container pt={2} pb={0} width={"large"}>
          <motion.div
              variants={formVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{}}
            >            
            <Wrap text={description} locations={locations}/>
            </motion.div>
          </Container>
        </Container>
      </div>
    </>
  )
}

export default ContactSection
