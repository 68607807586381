import React from "react"
import style from "./wrap.mod.scss"
import Form from "./form"

const Wrap = ({ text, locations }) => {
  return (
    <>
      <div className={style.wrap}>
        <Form locations={locations} />
        <div
          className={style.wrap__text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </>
  )
}

export default Wrap
