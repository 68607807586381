import React, { useState, useEffect } from "react"
import style from "./form.mod.scss"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import ArrowRightBlack from "../icons/arrow-right-black"
import { graphql } from "gatsby"

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  subject: yup.string().required(),
  info: yup.string().required(),
})

const Form = ({ locations }) => {
  const pageUrl = typeof window !== "undefined" ? window.location.href : ""
  const [pageTitle, setPageTitle] = useState("")
  const [error, setError] = useState(false) // New state variable
  const [submitted, setSubmitted] = useState(false) // New state variable

  useEffect(() => {
    setPageTitle(document.title)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmitHandler = (data) => {
    data.pageUrl = pageUrl
    data.pageTitle = pageTitle

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify(data)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }

    fetch(
      "https://hook.us1.make.com/ktoqag2rptqjqckoxn5vc9fqpw7nmukn",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (result === "Accepted") {
          setSubmitted(true) // Update the submitted state
        } else {
          // handle error here
          setError(true)
        }
      })
      .catch((error) => {
        // handle error here
        setError(true)
      })
  }

  return (
    <>
      <div className={style.form}>
        {!submitted && !error && (
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            name="contactForm"
            method="POST"
          >
            <input
              {...register("name")}
              placeholder="Full name*"
              type="text"
              required
            />
            <p>{errors.email?.message}</p>

            {locations && (
              <>
                <select
                  {...register("location")}
                  placeholder="Location*"
                  required
                >
                  <option selected disabled>
                    {"Location*"}
                  </option>
                  {locations.map((location) => (
                    <option value={location.title}>{location.title}</option>
                  ))}
                </select>
                <p>{errors.location?.message}</p>
              </>
            )}

            <input
              {...register("email")}
              placeholder="Email*"
              type="email"
              required
            />
            <p>{errors.password?.message}</p>

            <input
              {...register("phone")}
              placeholder="Phone*"
              type="text"
              required
            />
            <p>{errors.phone?.message}</p>

            <input
              {...register("subject")}
              placeholder="Subject*"
              type="text"
              required
            />
            <p>{errors.subject?.message}</p>

            <textarea
              {...register("info")}
              placeholder="What would you like to change?*"
              type="text"
              required
            />
            <p>{errors.subject?.message}</p>
            <button className={style.form__button} type="submit">
              {" "}
              <div className={style.form__submit}>
                <span>Submit</span>
                <ArrowRightBlack />
              </div>
            </button>
          </form>
        )}
        {submitted && (
          <div>
            <p className={style.form__text}>
              {
                "Thank you for your message. Someone will be in touch with you shortly."
              }
            </p>
          </div>
        )}
        {error && (
          <div>
            <p className={style.form__text}>
              {"There seems to be an issue, please contact us."}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default Form

export const query = graphql`
  {
    allDatoCmsContactPage {
      nodes {
        locations {
          title
        }
      }
    }
  }
`
