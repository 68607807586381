import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/contact/intro"
import Container from "../components/container/container"
import Wrap from "../components/contact/wrap"
import ContactBlur from "../components/global/contact-blur"
import ContactSection from "../components/contact/contact"
import Footer from "../components/footer/footer"
import { Waypoint } from "react-waypoint"

const Contact = ({ data: { datoCmsContactPage } }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])

  const handleBackToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const handleLightScroll = (active = false) => {
    if (document.readyState === "complete") {
      if (active) {
        document.body.classList.add("nav--light-footer")
      } else {
        document.body.classList.remove("nav--light-footer")
      }
    }
  }
  return (
    <Layout
      seo={datoCmsContactPage.seoMetaTags}
      yPos={offset}
      isDarkLogo
      noButton
      isNotHome
      handleBackToTop={handleBackToTop}
      showBackToTop={true}
    >
      <ContactBlur />
      <Container pt={4} pb={4} width={"large"} gutters={false}>
        <ContactSection
          title={datoCmsContactPage.title}
          description={datoCmsContactPage.description}
          locations={datoCmsContactPage.locations}
          navigation={true}
        />
      </Container>
      <Waypoint
        onEnter={({ previousPosition, currentPosition, event }) => {
          if (currentPosition === "inside") {
            handleLightScroll(true)
          } else {
            handleLightScroll(false)
          }
        }}
        onLeave={({ previousPosition, currentPosition, event }) => {
          if (currentPosition !== "inside") {
            handleLightScroll(false)
          } else {
            handleLightScroll(true)
          }
        }}
      >
        <div>
          <Footer />
        </div>
      </Waypoint>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    datoCmsContactPage {
      id: originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      locations {
        title
      }
    }
  }
`
