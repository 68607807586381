import React from "react"
import style from "./intro.mod.scss"
import Navigator from "../global/navigator"

const Intro = ({ title, navigation }) => {
  return (
    <div className={style.intro}>
      {navigation && (
        <div className={style.intro__back}>
          <Navigator customPath={"/#work"}>{"< Back"}</Navigator>
        </div>
      )}
      <div className={style.intro__heading}>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    </div>
  )
}

export default Intro
