import * as React from "react"
import style from "./contactblur.mod.scss"

const ContactBlur = (props) => {
  return (
    <div className={style.blur}>
        
    </div>
  )
}
export default ContactBlur
